<template>
  <v-card>
    <v-card-title>{{ $t("forms.security.title") }}</v-card-title>
    <v-form v-if="user" @submit.prevent="savePassword">
      <v-container>
        <v-row dense>
          <v-col cols="12" sm="12" v-show="false">
            <v-text-field
              name="email"
              type="email"
              :value="user.email"
            ></v-text-field>
          </v-col>

          <v-col v-if="!usesOAuth" cols="12" sm="12">
            <v-text-field
              name="oldPassword"
              type="password"
              v-model="form.oldPassword"
              :error-messages="oldPasswordErrors"
              :success="shouldAppendValidClass($v.form.oldPassword)"
            >
              <template v-slot:label>
                {{ $t("forms.security.oldPassword") }}
                <span class="error--text">*</span>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <v-text-field
              name="newPassword"
              type="password"
              v-model="form.newPassword"
              :error-messages="newPasswordErrors"
              :success="shouldAppendValidClass($v.form.newPassword)"
            >
              <template v-slot:label>
                {{ $t("forms.security.newPassword") }}
                <span class="error--text">*</span>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <v-text-field
              name="newPasswordConfirmation"
              type="password"
              v-model="form.newPasswordConfirmation"
              :error-messages="newPasswordConfirmationErrors"
              :success="shouldAppendValidClass($v.form.newPasswordConfirmation)"
            >
              <template v-slot:label>
                {{ $t("forms.security.newPasswordConfirmation") }}
                <span class="error--text">*</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-alert v-if="error" class="mt-6" dense outlined type="error">
          {{ errorMessage }}
        </v-alert>
        <v-alert v-else-if="success" class="mt-6" dense outlined type="success">
          {{ successMessage }}
        </v-alert>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn type="submit" color="success">
          {{ $t("forms.security.submit") }}
        </v-btn>
      </v-card-actions>
    </v-form>

    <DeleteAccountForm />
  </v-card>
</template>

<script>
/* eslint-disable */

import { mapState, mapGetters, mapActions } from "vuex";
import {
  required,
  requiredIf,
  sameAs,
  minLength
} from "vuelidate/lib/validators";
import DeleteAccountForm from "@/components/DeleteAccountForm";

export default {
  name: "PanelAccountTabSecurity",
  components: {
    DeleteAccountForm
  },
  data() {
    return {
      form: {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirmation: ""
      },
      error: null,
      success: null
    };
  },
  validations: {
    form: {
      oldPassword: {
        required: requiredIf(function() {
          return !this.usesOAuth;
        }),
        minLength: minLength(6)
      },
      newPassword: {
        required,
        minLength: minLength(6)
      },
      newPasswordConfirmation: {
        required,
        sameAsPassword: sameAs("newPassword")
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters({
      usesOAuth: "user/usesOAuth"
    }),
    errorMessage() {
      return this.$i18n.t(this.error);
    },
    successMessage() {
      return this.$i18n.t(this.success);
    },
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.form.oldPassword.$dirty) return errors;
      !this.$v.form.oldPassword.required &&
        errors.push(this.$i18n.t("forms.general.password.errors.required"));
      !this.$v.form.oldPassword.minLength &&
        errors.push(
          this.$i18n.t("forms.general.password.errors.minLength", { length: 6 })
        );
      return errors;
    },
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.form.newPassword.$dirty) return errors;
      !this.$v.form.newPassword.required &&
        errors.push(this.$i18n.t("forms.general.password.errors.required"));
      !this.$v.form.newPassword.minLength &&
        errors.push(
          this.$i18n.t("forms.general.password.errors.minLength", { length: 6 })
        );
      return errors;
    },
    newPasswordConfirmationErrors() {
      const errors = [];
      if (!this.$v.form.newPasswordConfirmation.$dirty) return errors;
      !this.$v.form.newPasswordConfirmation.required &&
        errors.push(
          this.$i18n.t("forms.general.passwordConfirmation.errors.required")
        );
      !this.$v.form.newPasswordConfirmation.sameAsPassword &&
        errors.push(
          this.$i18n.t("forms.general.passwordConfirmation.errors.match")
        );
      return errors;
    }
  },
  methods: {
    ...mapActions({
      updatePassword: "user/updatePassword"
    }),
    shouldAppendValidClass(field) {
      return !field.$invalid && field.$model && field.$dirty;
    },
    clearForm() {
      this.form.oldPassword = "";
      this.form.newPassword = "";
      this.form.newPasswordConfirmation = "";
      this.$v.$reset();
    },
    savePassword() {
      this.error = null;
      this.success = null;

      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        this.updatePassword({
          newPassword: this.form.newPassword,
          oldPassword: this.form.oldPassword
        })
          .then(response => {
            this.success = "forms.security.success";
            this.clearForm();
          })
          .catch(response => {
            switch (response.data.status) {
              case -1003:
                this.error = "forms.security.errors.incorrectOldPassword";
                break;
              case -1004:
                this.error = "forms.security.errors.passwordUpdateFailed";
                break;
              default:
                this.error = "errors.unexpectedError";
            }
            this.clearForm();
          });
      }
    }
  }
};
</script>
