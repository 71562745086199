<template>
  <v-form v-if="user" @submit.prevent="submitForm">
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn
            type="submit"
            color="error"
            :loading="loading"
            :outlined="!confirmed"
          >
            {{ label }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="success">
        <v-col cols="12">
          <v-alert class="my-0" dense outlined type="success">
            <i18n path="forms.deleteAccount.success">
              <template v-slot:emailAddress>
                <span class="font-italic">{{ user.email }}</span>
              </template>
            </i18n>
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-else-if="error">
        <v-col cols="12">
          <v-alert class="my-0" dense outlined type="error">
            {{ $t(error) }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
/* eslint-disable */

import { mapState, mapActions } from "vuex";

export default {
  name: "DeleteAccountForm",
  data() {
    return {
      confirmed: false,
      label: "",
      actionLabel: this.$i18n.t("forms.deleteAccount.action"),
      confirmationLabel: this.$i18n.t("forms.deleteAccount.confirmation"),
      loading: false,
      error: null,
      success: null
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  methods: {
    ...mapActions({
      sendDeleteAccount: "user/sendDeleteAccount"
    }),
    resetForm() {
      this.confirmed = false;
      this.label = this.actionLabel;
    },
    submitForm() {
      this.error = null;
      this.success = false;

      if (this.confirmed) {
        this.loading = true;

        this.sendDeleteAccount({
          userId: this.user.id
        })
          .then(response => {
            this.resetForm();
            this.loading = false;
            this.success = true;
          })
          .catch(response => {
            this.resetForm();
            this.loading = false;
            switch (response.data.status) {
              case -1009:
                this.error = "forms.deleteAccount.errors.failedToSend";
                break;
              default:
                this.error = "errors.unexpectedError";
            }
          });
      } else {
        this.confirmed = true;
        this.label = this.confirmationLabel;
      }
    }
  },
  created() {
    this.label = this.actionLabel;
  }
};
</script>
